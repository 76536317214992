
.MuiPaper-root-MuiDialog-paper{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
}

.MuiDialog-root {
  position: fixed;
  z-index: 9999999 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
  width: 85% !important;
  min-width: 250px !important;
  min-height: 200px !important;
  box-shadow: none !important;
  max-height: 400px !important;
  max-width: 85% !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16) !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    /* display: none; */

    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.MuiDialog-paper {
  max-width: 330px !important;
  max-height: 430px !important;
  margin: 4px !important;

  .css-fhpqww {
    margin: 8px !important;
  }
}

.MuiDialogActions-root {
  padding: 0px 8px 2px !important;
}
.MuiTimePickerToolbar-root {
  padding: 10px 20px !important;
}

//disable image drag and copying
img {
  -webkit-user-drag: none !important;
  -khtml-user-drag: none !important;
  -moz-user-drag: none !important;
  -o-user-drag: none !important;
  -user-drag: none !important;
  pointer-events: none;
}

.material-symbols-rounded-fill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-variation-settings: "FILL" 1, "wght" 600, "GRAD" 0, "opsz" 48;
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.material-symbols-rounded-outlined {
  font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 48;
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  position: fixed;
  z-index: 130000000010 !important;
  right: 0;
  bottom: 0;
  top: 0% !important;
  left: 0;
}

.indicatorIconButtonProps {
  color: rgba(0, 0, 0, 0.1);
  svg {
    font-size: 10px;
  }
}
.activeIndicatorIconButtonProps {
  color: rgba(0, 0, 0, 0.3);
  svg {
    font-size: 10px;
  }
}
.indicatorContainerProps {
  width: 100%;
  margin-top: -2px;
  text-align: center;
}
.indicatorContainerProps {
  width: 100%;
  margin-top: -2px;
  text-align: center;
}
.navButtonsProps {
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.04);
  color: #7e8392;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
.navButtonsProps {
  bottom: 0;
  top: calc(45% - 16px);
  height: 32px;
  background-color: transparent;
  z-index: 1;
}

.MuiBackdrop-root,
.MuiModal-backdrop {
  // background-color: transparent !important;
  border-radius: 8px;
}


$small: 300px;
$medium: 650px;


.chatWrap {
  position: relative;
  padding: 1rem 0.5rem 0.5rem;
  width: 50%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-color: rgb(231, 215, 215);
  overflow: hidden;
  overflow-y: overlay;
  @media screen and (max-width: $medium) {
    width: 90%;
  }
}

.singleMsgBox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.singleMsg {
  max-width: 70%;
  font-size: 1rem;
  background-color: tomato;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 6px 12px;
  text-align: left;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 6px 12px;
}

.inputBox {
  width: 50%;
  padding: 0.5rem 0;
  overflow: hidden;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $medium) {
    width: 90%;
  }
}

.inputField {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 1.2rem;
  padding: 8px;
  text-align: center;
}

.closeBtn {
  right: 0%;
  top: 0%;
  z-index: 1;
  width: 32px;
  height: 32px;
}
.infoBox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
